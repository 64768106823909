import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GuideModal from "../GuideModal/GuideModal.js";
import logo from "../../img/logo.png";
import caretDown from "../../img/caret-down.png";
import caretDownDark from "../../img/caret-down-dark.png";
import "./HeaderBar.scss";

const HeaderBar = () => {
  const guideModalOpen = useSelector((state) => state.guideModalOpen);
  const seasonDropDownOpen = useSelector((state) => state.seasonDropDownOpen);

  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div className="header-bar-container">
      <div className="header-bar">
        <img className="logo" src={logo} alt="logo" />
        <div className="logo-text">
          NBA
          <br />
          Highlight
          <br /> Library
        </div>
        <div
          className="season-selector"
          onClick={() => {
            if (seasonDropDownOpen)
              dispatch({ type: "setSeasonDropDownOpen", payload: false });
            else dispatch({ type: "setSeasonDropDownOpen", payload: true });
          }}
          onMouseOver={() => {
            const caret = document.querySelector(".season-selector-caret");
            caret.src = caretDownDark;
          }}
          onMouseLeave={() => {
            const caret = document.querySelector(".season-selector-caret");
            caret.src = caretDown;
          }}
        >
          <span className="season-selector-text">Season 2021-22</span>
          <img className="season-selector-caret" src={caretDown} alt="down" />
          {seasonDropDownOpen && (
            <div className="season-selector-drop-down">
              <div className="season-selector-drop-down-item">
                Season 2021-22
              </div>
              <div className="season-selector-drop-down-item drop-down-disabled">
                Season 2020-21
              </div>
              <div className="season-selector-drop-down-item drop-down-disabled">
                Season 2019-20
              </div>
              <div className="season-selector-drop-down-item drop-down-disabled">
                Season 2018-19
              </div>
            </div>
          )}
        </div>
        <div className="page-list">
          <Link
            className={`link-text page-item ${
              location.pathname === "/main" ? "selected-page-item" : ""
            }`}
            to="/main"
          >
            Highlight
          </Link>
          <Link
            className={`link-text page-item ${
              location.pathname === "/draft" ? "selected-page-item" : ""
            }`}
            to="/draft"
          >
            Draft
          </Link>
          <Link
            className={`link-text page-item ${
              location.pathname === "/salary" ? "selected-page-item" : ""
            }`}
            to="/salary"
          >
            Contract
          </Link>
          <div
            className="guide-button"
            onClick={() =>
              dispatch({ type: "setGuideModalOpen", payload: true })
            }
          >
            Guide
          </div>
          <div className="guide-button">Shop</div>
          <Link
            className={`link-text page-item ${
              location.pathname === "/contactus" ? "selected-page-item" : ""
            }`}
            to="/contactus"
          >
            Contact us
          </Link>
        </div>
      </div>
      <div className="header-bar-bottom"></div>

      {guideModalOpen && <GuideModal />}
    </div>
  );
};

export default HeaderBar;
