import "./PlayerInformation.scss";
import { useSelector } from "react-redux";

const PlayerInformation = () => {
  const selectedPlayer = useSelector((state) => state.selectedPlayer);
  return (
    <div className="player-information">
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer
            ? selectedPlayer.name ||
              `${selectedPlayer.firstName} ${selectedPlayer.lastName}`
            : "-"}
        </div>
        <div className="player-information-item-label">Name</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? selectedPlayer.pos : "-"}
        </div>
        <div className="player-information-item-label">Position</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer
            ? `${parseInt(selectedPlayer.hgt / 12)}-${selectedPlayer.hgt % 12}`
            : "-"}
        </div>
        <div className="player-information-item-label">Height</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? `${selectedPlayer.weight} lbs` : "-"}
        </div>
        <div className="player-information-item-label">Weight</div>
      </div>
    </div>
  );
};

export default PlayerInformation;
