import "./TeamSelector.scss";
import arrow_gray from "../../../../img/arrow_gray.png";
import arrow_blue from "../../../../img/arrow_blue.png";
import check from "../../../../img/check.png";
import { useSelector, useDispatch } from "react-redux";

const TeamSelector = () => {
  const teams = useSelector((state) => state.teams);
  const teamId = useSelector((state) => state.teamId);
  const dispatch = useDispatch();
  return (
    <div className="team-selector-container">
      <div className="team-selector">
        {teams.map((team, index) => (
          <div
            key={index}
            className={`team-selector-item ${
              teamId === team.tid ? "team-selected" : ""
            }`}
            onClick={() => dispatch({ type: "setTeamId", payload: team.tid })}
          >
            <img className="team-logo" src={team.imgURLSmall} alt="team logo" />
            {`${team.region} ${team.name}`}
            {teamId === team.tid && (
              <img className="check" src={check} alt="check" />
            )}
          </div>
        ))}
      </div>
      <div className="team-selector-control">
        <button
          className="team-selector-control-left btn-clear"
          onClick={() => {
            const teamSelector = document.querySelector(".team-selector");
            teamSelector.scrollBy({
              left: -160,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-left"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow left"
          />
        </button>
        <button
          className="team-selector-control-right btn-clear"
          onClick={() => {
            const teamSelector = document.querySelector(".team-selector");
            teamSelector.scrollBy({
              left: 160,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-right"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow-right"
          />
        </button>
      </div>
    </div>
  );
};

export default TeamSelector;
