import "./GuideModal.scss";
import close from "../../img/cancel.png";
import guide1 from "../../img/guide-1.png";
import guide2 from "../../img/guide-2.png";
import guide3 from "../../img/guide-3.png";
import guide4 from "../../img/guide-4.png";
import { useDispatch } from "react-redux";

const GuideModal = () => {
  const dispatch = useDispatch();
  return (
    <div className="guide-modal-container">
      <div className="guide-modal-backdrop" />
      <div className="guide-modal">
        <div className="guide-modal-title">
          User guide
          <img
            className="close-btn"
            src={close}
            onClick={() => {
              dispatch({ type: "setGuideModalOpen", payload: false });
            }}
            alt="close"
          />
        </div>
        <div className="guide-modal-description">
          1. Select conference
          <img src={guide1} alt="guide" />
        </div>
        <div className="guide-modal-description">
          2. Select team
          <img src={guide2} alt="guide" />
        </div>
        <div className="guide-modal-description">
          3. Select player
          <img src={guide3} alt="guide" />
        </div>
        <div className="guide-modal-description">
          4. Select highlight video
          <img src={guide4} alt="guide" />
        </div>
      </div>
    </div>
  );
};

export default GuideModal;
