import "./PlayerSelector.scss";
import arrow_gray from "../../../../img/arrow_gray.png";
import arrow_blue from "../../../../img/arrow_blue.png";
import check from "../../../../img/check.png";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const PlayerSelector = () => {
  const draftList = useSelector((state) => state.draftList);
  const selectedPlayer = useSelector((state) => state.selectedPlayer);
  const teams = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "setSelectedPlayer",
      payload: ["1", "DET", "Cade Cunningham", "Oklahoma State"],
    });
  }, [dispatch]);
  return (
    <div className="player-selector-container">
      <div className="player-selector">
        {draftList.map((player, index) => (
          <div
            key={index}
            className={`player-selector-item 
            ${player[2] === selectedPlayer[2] ? "player-selected" : ""}`}
            onClick={() =>
              dispatch({
                type: "setSelectedPlayer",
                payload: player,
              })
            }
          >
            <div className="player-pick">#{player[0]} Pick</div>
            <div className="player-team">
              <img
                className="team-image"
                src={
                  teams.find((team) => team.abbrev === player[1])?.imgURLSmall
                }
                alt="profile"
              />
              {player[1]}
            </div>
            <div className="player-name">{player[2]}</div>
            {player[2] === selectedPlayer[2] && (
              <img className="check" src={check} alt="check" />
            )}
          </div>
        ))}
      </div>
      <div className="player-selector-control">
        <button
          className="player-selector-control-left btn-clear"
          onClick={() => {
            const playerSelector = document.querySelector(".player-selector");
            playerSelector.scrollBy({
              left: -120,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-left"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow left"
          />
        </button>
        <button
          className="player-selector-control-right btn-clear"
          onClick={() => {
            const playerSelector = document.querySelector(".player-selector");
            playerSelector.scrollBy({
              left: 120,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-right"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow right"
          />
        </button>
      </div>
    </div>
  );
};

export default PlayerSelector;
