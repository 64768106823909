import "./SalaryPage.scss";
import salaryList from "../../data/2021_NBA_Salary.json";
import nbaroster from "../../data/2021-22.NBA.Roster.json";
import defaultPlayerImage from "../../img/defaultPlayerImage.png";

const SalaryPage = () => {
  const reformatPlayerName = (inputName) => inputName.split("\\")[0];
  const reformatNumber = (inputNumber) =>
    inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const convertSalaryStringToNumber = (inputSalary) =>
    parseInt(inputSalary.substring(1, inputSalary.length));
  const highestSalary = salaryList[0][3];

  return (
    <div className="salary-page-container">
      {salaryList.map((player) => {
        const [rank, name, team, salary] = player;
        const profileImgURL = nbaroster.players.filter(
          (player) => player.name === reformatPlayerName(name)
        )[0]?.imgURL;
        return (
          <div key={rank} className="player-salary-container">
            <div className="salary-rank">
              Rank
              <br />#{rank}
            </div>
            <div className="salary-profile-image-container">
              <img
                className="salary-profile-image"
                src={profileImgURL ? profileImgURL : defaultPlayerImage}
                alt="profile"
              />
            </div>
            <div className="salary-container-player-info">
              {team} / {reformatPlayerName(name)} <br />{" "}
              {reformatNumber(salary)}
            </div>
            <div className="salary-bar-container">
              <div
                className="salary-bar"
                style={{
                  width: `${
                    (convertSalaryStringToNumber(salary) /
                      convertSalaryStringToNumber(highestSalary)) *
                    100
                  }%`,
                }}
              ></div>
              <div
                className="salary-tooltip"
                style={{
                  left: `${
                    (convertSalaryStringToNumber(salary) /
                      convertSalaryStringToNumber(highestSalary)) *
                    100
                  }%`,
                }}
              >
                {reformatNumber(salary)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SalaryPage;
