import "./ContactUsPage.scss";

const ContactUsPage = () => {
  return (
    <div className="contact-us-page-container">
      <div className="contact-us-text">Contact Us</div>
      <div className="contact-us-description">
        This email will be sent to{" "}
        <u
          className="receiver-mail-address"
          onClick={() =>
            (window.location.href = "mailto:nbahighlightlibrary@gmail.com")
          }
        >
          nbahighlightlibrary@gmail.com
        </u>
        <div className="contact-us-not-working">
          ! Below form currently not working. Please send us an inquiry
          manually.
        </div>
      </div>
      <input
        className="sender-email"
        name="email"
        placeholder="Sender's email address"
        disabled
      />
      <input
        className="email-title"
        name="title"
        placeholder="Title"
        disabled
      />
      <textarea
        className="email-content"
        name="inquiry"
        placeholder="Inquiry"
        disabled
      />
      <div className="email-send-button">Send</div>
      <div className="contact-us-footer"></div>
    </div>
  );
};

export default ContactUsPage;
