import "./PlayerInformation.scss";
import { useSelector } from "react-redux";

const PlayerInformation = () => {
  const selectedPlayer = useSelector((state) => state.selectedPlayer);
  return (
    <div className="player-information">
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? selectedPlayer[2] || `${selectedPlayer[2]}` : "-"}
        </div>
        <div className="player-information-item-label">Name</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? selectedPlayer[0] : "-"}
        </div>
        <div className="player-information-item-label">Pick</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? selectedPlayer[1] : "-"}
        </div>
        <div className="player-information-item-label">Team</div>
      </div>
      <div className="player-information-item">
        <div className="player-information-item-value">
          {selectedPlayer ? (selectedPlayer[3] ? selectedPlayer[3] : "-") : "-"}
        </div>
        <div className="player-information-item-label">College</div>
      </div>
    </div>
  );
};

export default PlayerInformation;
