import "./PlayList.scss";
import check from "../../../../img/check.png";
import { useSelector, useDispatch } from "react-redux";

const PlayList = () => {
  const videos = useSelector((state) => state.videos);
  const videoURL = useSelector((state) => state.videoURL);
  const dispatch = useDispatch();
  return (
    <div className="video-player-playlist">
      {videos?.length > 0 ? (
        videos.map((video, index) => (
          <div
            key={index}
            className={`playlist-item ${
              video.id.videoId === videoURL ? "playlist-selected" : ""
            }`}
            onClick={() =>
              dispatch({ type: "setVideoURL", payload: video.id.videoId })
            }
          >
            <img
              className="playlist-item-thumbnail"
              src={video.snippet.thumbnails.medium.url}
              alt="thumbnail"
            />
            <div className="playlist-item-description">
              <div className="playlist-item-title">{video.snippet.title}</div>
              <div className="playlist-item-channel-title">
                {video.snippet.channelTitle}
              </div>
            </div>
            {video.id.videoId === videoURL && (
              <img className="check" src={check} alt="check" />
            )}
          </div>
        ))
      ) : (
        <div className="no-playlist" />
      )}
    </div>
  );
};

export default PlayList;
