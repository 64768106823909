import "./VideoPlayer.scss";
import { useSelector } from "react-redux";

const VideoPlayer = () => {
  const videoURL = useSelector((state) => state.videoURL);
  return (
    <div>
      {videoURL ? (
        <iframe
          title="video-player"
          id="video-player"
          width="640"
          height="360"
          src={`https://www.youtube.com/embed/${videoURL}`}
          frameBorder="0"
          allowFullScreen="allowFullScreen"
        />
      ) : (
        <div className="no-video">
          <div className="no-video-instruction">Select team and player</div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
