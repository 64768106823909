import { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Redirect, useLocation } from "react-router-dom";
import HeaderBar from "./components/HeaderBar/HeaderBar.js";
import HighlightPage from "./components/HighlightPage/HighlightPage.js";
import DraftPage from "./components/DraftPage/DraftPage.js";
import SalaryPage from "./components/SalaryPage/SalaryPage.js";
import FooterBar from "./components/FooterBar/FooterBar";
import ContactUsPage from "./components/ConntactUsPage/ContactUsPage";
import "./App.scss";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(
    () => window.scrollTo({ top: 0, behavior: "smooth" }),
    [location.pathname]
  );

  return (
    <>
      <HeaderBar />
      <Route exact path="/">
        <Redirect to="/main" />
      </Route>
      <Route exact path="/main" component={HighlightPage} />
      <Route exact path="/draft" component={DraftPage} />
      <Route exact path="/salary" component={SalaryPage} />
      <Route exact path="/contactus" component={ContactUsPage} />
      <FooterBar />
    </>
  );
}

export default App;
