import "./ConferenceSelector.scss";
import easternConferenceLogo from "../../../../img/eastern_conference_logo.gif";
import westernConferenceLogo from "../../../../img/western_conference_logo.gif";
import { useSelector, useDispatch } from "react-redux";

const ConferenceSelector = () => {
  const conference = useSelector((state) => state.conference);
  const dispatch = useDispatch();
  return (
    <div className="conference-selector">
      <div
        className={`western-conference ${
          conference === 1 ? "conference-selected" : ""
        }`}
        onClick={() => dispatch({ type: "setConference", payload: 1 })}
      >
        <img
          className="western-conference-logo"
          src={westernConferenceLogo}
          alt="wetern conference"
        />
        Western Conference
      </div>
      <div
        className={`eastern-conference ${
          conference === 0 ? "conference-selected" : ""
        }`}
        onClick={() => dispatch({ type: "setConference", payload: 0 })}
      >
        <img
          className="eastern-conference-logo"
          src={easternConferenceLogo}
          alt="wetern conference"
        />
        Eastern Conference
      </div>
    </div>
  );
};

export default ConferenceSelector;
