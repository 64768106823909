import { createStore } from "redux";

const initialState = {
  seasonDropDownOpen: false,
  guideModalOpen: false,
  conference: 1,
  teams: [],
  teamId: 9,
  roster: [],
  selectedPlayer: {
    hgt: 75,
    imgURL:
      "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/201939.png",
    name: "Stephen Curry",
    pos: "PG",
    tid: 9,
    weight: 185,
  },
  selectedPlayerName: null,
  videos: [],
  videoURL: null,
  draftList: [],
  salaryList: [],
};

function reducer(state = initialState, action) {
  if (action.type === "setSeasonDropDownOpen") {
    return {
      ...state,
      seasonDropDownOpen: action.payload,
    };
  } else if (action.type === "setGuideModalOpen") {
    return {
      ...state,
      guideModalOpen: action.payload,
    };
  } else if (action.type === "setConference") {
    return {
      ...state,
      conference: action.payload,
    };
  } else if (action.type === "setTeams") {
    return {
      ...state,
      teams: action.payload,
    };
  } else if (action.type === "setTeamId") {
    return {
      ...state,
      teamId: action.payload,
    };
  } else if (action.type === "setRoster") {
    return {
      ...state,
      roster: action.payload,
    };
  } else if (action.type === "setSelectedPlayer") {
    return {
      ...state,
      selectedPlayer: action.payload,
    };
  } else if (action.type === "setSelectedPlayerName") {
    return {
      ...state,
      selectedPlayerName: action.payload,
    };
  } else if (action.type === "setVideos") {
    return {
      ...state,
      videos: action.payload,
    };
  } else if (action.type === "setVideoURL") {
    return {
      ...state,
      videoURL: action.payload,
    };
  } else if (action.type === "setDraftList") {
    return {
      ...state,
      draftList: action.payload,
    };
  } else {
    return state;
  }
}

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
