import "./DraftPage.scss";
import PlayerSelector from "./components/PlayerSelector/PlayerSelector.js";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer.js";
import PlayerInformation from "./components/PlayerInformation/PlayerInformation.js";
import StatTable from "./components/StatTable/StatTable.js";
import AdvertisementList from "./components/AdvertisementList/AdvertisementList.js";
import PlayList from "./components/PlayList/PlayList";
import { useEffect } from "react";
import data from "../../data/2021_NBA_Draft.json";
import teamData from "../../data/2021-22.NBA.Roster.json";
import { useSelector, useDispatch } from "react-redux";
import playerYoutubeData from "../../data/youtubeData_draft_20211011.json";

const DraftPage = () => {
  const dispatch = useDispatch();
  const draftList = useSelector((state) => state.draftList);
  const selectedPlayer = useSelector((state) => state.selectedPlayer);

  useEffect(() => {
    dispatch({
      type: "setDraftList",
      payload: data,
    });

    dispatch({
      type: "setTeams",
      payload: teamData.teams,
    });
  }, [draftList, dispatch]);

  useEffect(() => {
    if (!selectedPlayer) return;
    if (playerYoutubeData[selectedPlayer[2]])
      dispatch({
        type: "setVideos",
        payload: playerYoutubeData[selectedPlayer[2]],
      });
  }, [selectedPlayer, dispatch]);

  return (
    <div className="draft-page-container">
      <PlayerSelector />
      <div className="content-section">
        <div>
          <VideoPlayer />
          <PlayerInformation />
          <StatTable />
          <AdvertisementList />
        </div>
        <PlayList />
      </div>
    </div>
  );
};

export default DraftPage;
