import "./FooterBar.scss";
import { Link } from "react-router-dom";
import facebookLogo from "../../img/facebook.png";
import twitterLogo from "../../img/twitter.png";
import youtubeLogo from "../../img/youtube.png";
import instagramLogo from "../../img/instagram.png";

const FooterBar = () => {
  return (
    <div className="footer-bar">
      <div className="footer-bar-sitemap">
        <Link className="link-text footer-bar-sitemap-item" to="/main">
          Highlight
        </Link>
        <Link className="link-text footer-bar-sitemap-item" to="/draft">
          Draft
        </Link>
        <Link className="link-text footer-bar-sitemap-item" to="/salary">
          Salary
        </Link>
      </div>
      <div className="footer-media">
        <img
          className="footer-media-item"
          src={facebookLogo}
          alt="facebook"
          onClick={() =>
            window.open(
              "https://www.facebook.com/NBA-Highlight-Library-101657778968687/?tn-str=k*F",
              "_blank"
            )
          }
        />
        <img
          className="footer-media-item"
          src={twitterLogo}
          alt="twitter"
          onClick={() =>
            window.open("https://twitter.com/NBAHighlightLib", "_blank")
          }
        />
        <img
          className="footer-media-item"
          src={youtubeLogo}
          alt="youtube"
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCp92y9BCNQKc80bQ2axgNAg",
              "_blank"
            )
          }
        />
        <img
          className="footer-media-item"
          src={instagramLogo}
          alt="instagram"
          onClick={() =>
            window.open(
              "https://www.instagram.com/nba_highlight_library/",
              "_blank"
            )
          }
        />
      </div>
      <div className="footer-copyright">
        Copyright ⓒ Peppermintc, All rights reserved.
      </div>
    </div>
  );
};

export default FooterBar;
