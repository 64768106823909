import "./HighlightPage.scss";
import ConferenceSelector from "./components/ConferenceSelector/ConferenceSelector.js";
import TeamSelector from "./components/TeamSelector/TeamSelector.js";
import PlayerSelector from "./components/PlayerSelector/PlayerSelector.js";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer.js";
import PlayerInformation from "./components/PlayerInformation/PlayerInformation.js";
import StatTable from "./components/StatTable/StatTable.js";
import AdvertisementList from "./components/AdvertisementList/AdvertisementList.js";
import PlayList from "./components/PlayList/PlayList";
import { useEffect } from "react";
import data from "../../data/2021-22.NBA.Roster.json";
import { useSelector, useDispatch } from "react-redux";
import playerYoutubeData from "../../data/youtubeData_highlight_20211011.json";

const HighlightPage = () => {
  const dispatch = useDispatch();
  const conference = useSelector((state) => state.conference);
  const teamId = useSelector((state) => state.teamId);
  const selectedPlayer = useSelector((state) => state.selectedPlayer);
  const selectedPlayerName = useSelector((state) => state.selectedPlayerName);

  useEffect(() => {
    dispatch({
      type: "setTeams",
      payload: data.teams.filter((team) => team.cid === conference),
    });
  }, [conference, dispatch]);

  useEffect(() => {
    dispatch({
      type: "setRoster",
      payload: data.players.filter((player) => player.tid === teamId),
    });
  }, [teamId, dispatch]);

  useEffect(() => {
    dispatch({
      type: "setSelectedPlayer",
      payload: {
        hgt: 75,
        imgURL:
          "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/201939.png",
        name: "Stephen Curry",
        pos: "PG",
        tid: 9,
        weight: 185,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (!selectedPlayer) return;
    dispatch({
      type: "setSelectedPlayerName",
      payload:
        selectedPlayer.name ||
        `${selectedPlayer.firstName} ${selectedPlayer.lastName}`,
    });
  }, [selectedPlayer, dispatch]);

  useEffect(() => {
    if (!selectedPlayerName) return;
    if (playerYoutubeData[selectedPlayerName])
      dispatch({
        type: "setVideos",
        payload: playerYoutubeData[selectedPlayerName],
      });
  }, [selectedPlayer, selectedPlayerName, dispatch]);
  return (
    <div className="highlight-page-container">
      <ConferenceSelector />
      <TeamSelector />
      <PlayerSelector />

      <div className="content-section">
        <div>
          <VideoPlayer />
          <PlayerInformation />
          <StatTable />
          <AdvertisementList />
        </div>
        <PlayList />
      </div>
    </div>
  );
};

export default HighlightPage;
