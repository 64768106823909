import "./StatTable.scss";

const StatTable = () => {
  return (
    <table className="stat-table">
      <tbody>
        <tr className="stat-row">
          <td className="stat-name">DATE</td>
          <td className="stat-name">TEAM</td>
          <td className="stat-name">MIN</td>
          <td className="stat-name">PTS</td>
          <td className="stat-name">FGM</td>
          <td className="stat-name">FG%</td>
          <td className="stat-name">3PM</td>
          <td className="stat-name">3P%</td>
          <td className="stat-name">FTM</td>
          <td className="stat-name">FTA</td>
          <td className="stat-name">FT%</td>
          <td className="stat-name">OREB</td>
          <td className="stat-name">DREB</td>
          <td className="stat-name">REB</td>
          <td className="stat-name">AST</td>
          <td className="stat-name">TOV</td>
          <td className="stat-name">STL</td>
          <td className="stat-name">BLK</td>
          <td className="stat-name">+/-</td>
        </tr>
        <tr className="stat-row">
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
        </tr>
        <tr className="stat-row">
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
        </tr>
        <tr className="stat-row">
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
        </tr>
        <tr className="stat-row">
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
          <td className="stat-value">-</td>
        </tr>
      </tbody>
    </table>
  );
};

export default StatTable;
