import "./AdvertisementList.scss";

const AdvertisementList = () => {
  return (
    <div>
      <div
        className="advertisement-card-youtube-channel"
        onClick={() =>
          window.open(
            "https://www.youtube.com/channel/UCd_EkHbEutirFl_XSrg95kA",
            "_blank"
          )
        }
      >
        <img
          className="advertisement-card-youtube-channel-image"
          src="https://yt3.ggpht.com/ytc/AKedOLTekVaOLMH25zJELTyrEq8kcqa0AeSLtdzO8uEsaA=s88-c-k-c0x00ffffff-no-rj"
          alt="channel"
        />
        <div className="advertisement-card-youtube-channel-name">
          GD's Latest Highlights
        </div>
      </div>
      <div
        className="advertisement-card-youtube-channel"
        onClick={() =>
          window.open(
            "https://www.youtube.com/user/https://www.youtube.com/watch?v=e6uyigc3L2U",
            "_blank"
          )
        }
      >
        <img
          className="advertisement-card-youtube-channel-image"
          src="https://yt3.ggpht.com/ytc/AKedOLSoaBco0UJpjv1LNU3H_8Ss-Zt0xTXsKsyhiGYmHQ=s88-c-k-c0x00ffffff-no-rj"
          alt="channel"
        />
        <div className="advertisement-card-youtube-channel-name">
          Hooper Highlights
        </div>
      </div>
      <div
        className="advertisement-card-youtube-channel"
        onClick={() =>
          window.open(
            "https://www.youtube.com/channel/UCGxh-eMTAcAAkZlDFS5vSfA",
            "_blank"
          )
        }
      >
        <img
          className="advertisement-card-youtube-channel-image"
          src="https://yt3.ggpht.com/4LCRsh4zWM5AdEWbx9e7pBPxLkEwzvp5_OccMxbGBlItKKY3npJsQGle0NtPbFD8Qaf32r2S=s88-c-k-c0x00ffffff-no-rj"
          alt="channel"
        />
        <div className="advertisement-card-youtube-channel-name">
          Ximo Pierto
        </div>
      </div>
      <div
        className="advertisement-card"
        onClick={() => (window.location.href = "mailto:harry069191@gmail.com")}
      >
        Advertisement
      </div>
    </div>
  );
};

export default AdvertisementList;
