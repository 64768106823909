import "./PlayerSelector.scss";
import arrow_gray from "../../../../img/arrow_gray.png";
import arrow_blue from "../../../../img/arrow_blue.png";
import check from "../../../../img/check.png";
import { useSelector, useDispatch } from "react-redux";

const PlayerSelector = () => {
  const roster = useSelector((state) => state.roster);
  const selectedPlayerName = useSelector((state) => state.selectedPlayerName);
  const dispatch = useDispatch();
  return (
    <div className="player-selector-container">
      <div className="player-selector">
        {roster.map((player, index) => (
          <div
            key={index}
            className={`player-selector-item ${
              (player.name || `${player.firstName} ${player.lastName}`) ===
              selectedPlayerName
                ? "player-selected"
                : ""
            }`}
            onClick={() =>
              dispatch({
                type: "setSelectedPlayer",
                payload: player,
              })
            }
          >
            <img className="profile-image" src={player.imgURL} alt="profile" />
            <div className="player-name">
              {player.name || `${player.firstName} ${player.lastName}`}
            </div>
            <div className="player-position">{player.pos}</div>
            {selectedPlayerName ===
              (player.name || `${player.firstName} ${player.lastName}`) && (
              <img className="check" src={check} alt="check" />
            )}
          </div>
        ))}
      </div>
      <div className="player-selector-control">
        <button
          className="player-selector-control-left btn-clear"
          onClick={() => {
            const playerSelector = document.querySelector(".player-selector");
            playerSelector.scrollBy({
              left: -120,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-left"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow left"
          />
        </button>
        <button
          className="player-selector-control-right btn-clear"
          onClick={() => {
            const playerSelector = document.querySelector(".player-selector");
            playerSelector.scrollBy({
              left: 120,
              behavior: "smooth",
            });
          }}
        >
          <img
            className="arrow-right"
            src={arrow_gray}
            onMouseOver={(e) => (e.target.src = arrow_blue)}
            onMouseOut={(e) => (e.target.src = arrow_gray)}
            alt="arrow right"
          />
        </button>
      </div>
    </div>
  );
};

export default PlayerSelector;
